'use client';

import classnames from 'classnames';
import React, {
  useEffect, useState,
} from 'react';
import { useAtomValue, useAtom } from 'jotai';
import styles from './Home.module.scss';
import deviceScreenSize, { ScreenSize } from '../../store/deviceScreenSize';
import { useSectionOffsetScroll } from '../../useSectionOffsetScroll';
import activeHomeSection, { prevActiveHomeSection, Section } from '../../store/activeHomeSection';

const getDotPosition = (currentScreenSize: ScreenSize, section: Section) => {
  if (section === Section.FIRST) {
    const firstSection = document.getElementById('firstSection');
    const heroTitleElement = firstSection?.children[1];
    const heroTitleRect = heroTitleElement?.getBoundingClientRect();
    const topShift = currentScreenSize === ScreenSize.TABLET_LANDSCAPE ? -80 : 50;
    const dotPosition = Math.round((heroTitleRect?.top || 0) + (window.scrollY || 0) + (heroTitleRect?.height || 0) + topShift);

    return dotPosition;
  } else if (section === Section.SECOND) {
    const secondSection = document.getElementById('secondSection');
    const secondSectionRect = secondSection?.getBoundingClientRect();
    const dotPosition = (secondSectionRect?.top || 0) + (window.scrollY || 0) + 100;

    return dotPosition;
  }

  return 0;
};

function HomepageSidebar() {
  const [activeSection, setActiveSection] = useAtom(activeHomeSection);
  const [prevActiveSection, setPrevActiveSection] = useAtom(prevActiveHomeSection);
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const deviceSize = useAtomValue(deviceScreenSize);
  const [sidebarDotPosition, setSidebarDotPosition] = useState('calc(100vh - 100px)');

  const firstSectionOffset = useSectionOffsetScroll('firstSection');
  const secondSectionOffset = useSectionOffsetScroll('secondSection');
  const thirdSectionOffset = useSectionOffsetScroll('thirdSection');
  const fourthSectionOffset = useSectionOffsetScroll('fourthSection');

  useEffect(() => {
    const secondSection = document.getElementById('secondSection')?.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const isSecondSectionActive = (secondSection?.top || 0) < (windowHeight - 300);

    if (fourthSectionOffset > (document?.getElementById('consultationFormId')?.offsetTop || 300)) {
      if (activeSection !== Section.FOURTH) {
        setActiveSection(prev => {
          setPrevActiveSection(prev);
          return Section.FOURTH;
        });
      }
    } else if (thirdSectionOffset > (document?.getElementById('techStackContentId')?.offsetTop || 300)) {
      if (activeSection !== Section.THIRD) {
        setActiveSection(prev => {
          setPrevActiveSection(prev);
          return Section.THIRD;
        });
      }
    } else if (isSecondSectionActive) {
      if (activeSection !== Section.SECOND) {
        setActiveSection(prev => {
          setPrevActiveSection(prev);
          return Section.SECOND;
        });
      }
    } else if (activeSection !== Section.FIRST) {
      setActiveSection(prev => {
        setPrevActiveSection(prev);
        return Section.FIRST;
      });
    }
  }, [firstSectionOffset, secondSectionOffset, thirdSectionOffset, fourthSectionOffset]);

  useEffect(() => {
    let consultationForm = document.getElementById('consultationFormId');
    let thirdSection = document.getElementById('thirdSection');
    let fourthSection = document.getElementById('fourthSection');
    let techStackContent = document.getElementById('techStackContentId');

    let timeout: ReturnType<typeof setTimeout>;
    if (fourthSectionOffset > (consultationForm?.offsetTop || 300)) {
      setSidebarDotPosition(
        `calc(${(consultationForm?.offsetTop || 0)
        + (fourthSection?.offsetTop || 0) + (consultationForm?.offsetHeight || 0)
        + 2}px`,
      );
    } else if (thirdSectionOffset > (techStackContent?.offsetTop || 300)) {
      setSidebarDotPosition(`calc(${(techStackContent?.offsetTop || 0) + (thirdSection?.offsetTop || 0)}px`);
    } else if (activeSection === Section.SECOND) {
      if (prevActiveSection && prevActiveSection < activeSection) {
        setSidebarDotPosition(`${getDotPosition(deviceSize, Section.SECOND) - 100}px`);
        timeout = setTimeout(() => {
          setSidebarDotPosition(`${getDotPosition(deviceSize, Section.SECOND)}px`);
        }, 1100);
      } else {
        setSidebarDotPosition(`${getDotPosition(deviceSize, Section.SECOND)}px`);
      }
    } else {
      setSidebarDotPosition(`${getDotPosition(deviceSize, Section.FIRST)}px`);
    }

    return () => clearTimeout(timeout);
  }, [activeSection, deviceSize]);

  return (
    <aside className={styles.sidebar}>
      <div
        className={styles.sidebar__dot}
        style={{
          top: sidebarDotPosition,
        }}
      />
      <div
        className={classnames(styles.sidebar__dot_trail, styles.sidebar__dot)}
        style={{
          top: sidebarDotPosition,
        }}
      />
      <div
        className={classnames(styles.sidebar__dot_trail2, styles.sidebar__dot)}
        style={{
          top: sidebarDotPosition,
        }}
      />
      <button
        aria-label='Toggle menu'
        type='button'
        onClick={() => setIsMenuOpened(!isMenuOpened)}
        className={classnames(styles.menuButton, {
          [styles.menuButton_active]: isMenuOpened,
        })}
      >
        <div className={styles.menuButton__line} />
        <div className={styles.menuButton__line} />
      </button>
    </aside>
  );
}

export default HomepageSidebar;
